import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/_guards/auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { NavigationEnd, Router } from '@angular/router';

declare global {
  interface Window {
    hj: any
  }
}
declare const environment;
declare const userGuiding;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isProductionSendUserIdentity();
      }
    });
  }

  private identifyOnHotjar(userId: string, organizationId: string, organizationName: string) {
    if (typeof window.hj === 'undefined') {
      return;
    }

    window.hj(
      'identify',
      userId,
      {
        companyId: organizationId,
        companyDescription: organizationName,
      },
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    const currentAccount = localStorage.getItem('currentAccount') || '';
    const currentOrganization = localStorage.getItem('organization') || '';

    // TODO: improve way to check when to apply bearer token. This is only a workaround
    if (
      !request.url.includes('/authorize') &&
      !request.url.includes('googleapis') &&
      !request.url.includes('/logout')
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true
      });
    }

    if (request.method !== 'GET') {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'Acesso-Csrf-Token': localStorage.getItem('csrf_token')
        },
        withCredentials: true
      });
    }

    if (request.method !== 'GET' && localStorage.getItem('csrf_token') !== 'null') {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'Acesso-Csrf-Token': localStorage.getItem('csrf_token')
        },
        withCredentials: true
      });
    }

    if (request.method !== 'GET' && localStorage.getItem('csrf_token') !== 'null') {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'Acesso-Csrf-Token': localStorage.getItem('csrf_token')
        },
        withCredentials: true
      });
    }

    if (request.url.includes('/v1') && !request.url.includes('/api/v1')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'Acesso-Csrf-Token': localStorage.getItem('csrf_token'),
          'Acesso-Account-Id': currentAccount,
          'Acesso-Org-Id': currentOrganization
        },
        withCredentials: true
      });
    }

    return next.handle(request).pipe(
      map(res => {
        if (res instanceof HttpResponse && res?.headers?.keys().includes('acesso-csrf-token')) {
          const csrf_token = res?.headers.get('acesso-csrf-token');
          localStorage.setItem('csrf_token', csrf_token);
        }
        return res;
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.authService.logout();
          }
        }
        return throwError(err);
      })
    );
  }

  isProductionSendUserIdentity(): void {
    const infos = JSON.parse(localStorage.getItem('user'))
    const organizationId = localStorage.getItem('organization');
    const organizationName = localStorage.getItem('organizationName');
    if (environment.current_environment === 'production') {
      userGuiding.identify(infos.user_id, { email: infos.user_email, Organization: organizationName, Product: 'unico | people', User: 'RH' });
      this.identifyOnHotjar(infos.user_id, organizationId, organizationName);
    }
  }
}
